import React, { FC, useEffect, useState } from "react";
import { ReconciliationStatement, RecsStatementStatus } from "../../../models/reconciliationStatement";
import RecsStatementsSidenav from "./recsStatementsSidenav";
import { getRecsStatementMonths, getRecsStatements } from "../../../services/get.data.service";
import { ResponseData } from "../../../models";
import HubService from "../../../services/hub.service";
import { IFileWithMeta } from "react-dropzone-uploader";
import { ResultsTableRowData } from "../settings/pages/types";
import SettingsTable from "../settings/shared/table/table";
import FileUploadDocumentsModal from "../../shared/actionModals/fileUploadModalDocuments";
import downloadStatements from "./downloadStatements";
import { dbDateStringToLocalDate } from "../../shared/helpers/dates";

const RecsStatements: FC = () => {

  const [ recsStatementsHubConnection ] = useState(new HubService("RecsStatementHub"));
  const [ downloadStatementId, setDownloadStatementId ] = useState<number|null>(null);
  const [ showUpload, setShowUpload ] = useState<boolean>(false);
  const [ statements, setStatements ] = useState<Map<number,ReconciliationStatement>>(new Map());
  const [ yearMonthList, setYearMonthList ] = useState<string[]>([]);
  const [ yearMonth, setYearMonth ] = useState<string>("");

  const loadMonthData = async () => {
    return await getRecsStatementMonths().then((res: ResponseData<string[]>): void =>{
      setYearMonthList(res.data);
      setYearMonth(res.data[0]);
    });
  }

  const loadStatementData = async (): Promise<void> => {
    return await getRecsStatements(yearMonth).then((res: ResponseData<ReconciliationStatement[]>): void => {
      setStatements(new Map(res.data.map((statement: ReconciliationStatement) => {
        return [statement.reconciliationStatementId, statement];
      })));
    });
  };

  const invalidFileName = (file: IFileWithMeta): boolean|string => {
    if (file.file.name.match(/[&%@!]/g) !== null) {
      return "File name contains invalid characters  &,%,@ or !";
    }
    return false;
  }

  useEffect(() => {
    loadMonthData();
    recsStatementsHubConnection.start().then(() => {
      recsStatementsHubConnection.listen("RecsStatementsUpdated", () => {
        loadStatementData();
      });
    });
    return (): void => {
      recsStatementsHubConnection.stop();
    };
  }, []);

  useEffect(() => {
    if (yearMonth !== "") {
      loadStatementData();
    }
  }, [yearMonth]);

  useEffect(() => {
    if (downloadStatementId !== null) {
      downloadStatements(downloadStatementId, statements.get(downloadStatementId)?.fileName || "")
      setDownloadStatementId(null);
    }
  }, [downloadStatementId]);

  const truncateErrorMessage = (message: string): string => {
    return message?.length > 70 ? message.substring(0, 69)+'\u2026' : message
  }

  const headers: string[] = ["Name", "Progress", "Uploaded at", "Finished At", ""];
  const rows: ResultsTableRowData[] = [...statements.values()].map((statement: ReconciliationStatement) => {
    return {
      id: statement.reconciliationStatementId,
      data: [
        statement.fileName,
        statement.status === RecsStatementStatus.Failed ?
          <span className="badge badge-pill badge-danger">Failed: {truncateErrorMessage(statement.errorMessage)}</span> :
          RecsStatementStatus[statement.status],
          dbDateStringToLocalDate(statement.uploadedAt).toLocaleString(),
        statement.finishedProcessingAt ? 
          dbDateStringToLocalDate(statement.finishedProcessingAt).toLocaleString() : 
          "", 
        statement.status === RecsStatementStatus["Completed Successfully"] ? 
          <div
            style={{ textAlign: "center" }}
            key={statement.reconciliationStatementId}
          >
            <button
              className="btn btn-secondary"
              onClick={() => setDownloadStatementId(statement.reconciliationStatementId)}
            >
              Download
            </button>
          </div> :
          ""
      ]
    };
  });

  return (
    <>
      <div className="page-layout">
        <RecsStatementsSidenav
          options={yearMonthList}
          value={yearMonth}
          onChange={setYearMonth}
        />
        <div className="content">
          <div className="row">
            <div className="col-10">
              <h2 className="page-title">Reconcilication Statements</h2>
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={()=>{
                  setShowUpload(true);
                  setYearMonth(yearMonthList[0])
                }}
              >
                Upload Statements
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <SettingsTable headers={headers} rows={rows} />
            </div>
          </div>
        </div>
        <FileUploadDocumentsModal
          accept="application/pdf"
          show={showUpload}
          handleClose={()=>{
            loadStatementData();
            setShowUpload(false);
          }}
          handleUpload={()=>loadStatementData()}
          hasNote={false}
          validate={invalidFileName}
          url="recsstatements/uploadstatement"
        />
      </div>
    </>
  );
};
export default RecsStatements;
