import { Reducer } from "react";

export enum FilterName {
  "caseReference" = "casereference",
  "description" = "description",
  "owner" = "owner",
  "team" = "team",
  "classification" = "classification",
  "slaStatus" = "slastatus",
  "subject" = "subject",
  "investorEmail" = "investoremail",
  "client" = "client",
  "status" = "status",
  "aiProbability" = "aiprobability",
  "studioSendFailed" = "studiosendfailed",
}

export type Filters = Record<FilterName,Filter>

export type Filter = {
  label: string;
  value: string;
  show: boolean;
};

export enum FiltersAction {
  CLEAR_VALUES,
  REPLACE,
  SHOW,
  SET_VALUE,
}

export type clearValuesAction = {
  type: FiltersAction.CLEAR_VALUES;
};

export type replaceFiltersAction = {
  type: FiltersAction.REPLACE;
  newFilters: Filters;
};

export type showAction = {
  type: FiltersAction.SHOW;
  filterName: FilterName;
};

export type setValueAction = {
  type: FiltersAction.SET_VALUE;
  filterName: FilterName;
  value: string;
};

export const filtersInitialState: Filters = {
  casereference: { label: "Case #", value: "", show: true },
  description: { label: "Description", value: "", show: true },
  owner: { label: "Assigned To", value: "", show: true },
  team: { label: "Team", value: "", show: true },
  classification: { label: "Classification", value: "", show: true },
  slastatus: { label: "SLA Status", value: "", show: false },
  subject: { label: "Subject", value: "", show: false },
  investoremail: { label: "From", value: "", show: false },
  client: { label: "Client", value: "", show: false },
  status: { label: "Status", value: "", show: false },
  aiprobability: { label: "Confidence", value: "", show: false },
  studiosendfailed: { label: "Failed to Send to Studio", value: "false", show: false },
};

const filtersReducer: Reducer<Filters, clearValuesAction | replaceFiltersAction | setValueAction | showAction> = (
  state,
  action
) => {
  switch (action.type) {
    case FiltersAction.CLEAR_VALUES: {
      const mutableFilters = { ...state };
      for (const key in state) {
        mutableFilters[key as FilterName].value = "";
      }
      return mutableFilters;
    }
    case FiltersAction.REPLACE: {
      return {...filtersInitialState, ...action.newFilters};
    }
    case FiltersAction.SHOW: {
      const mutableState = { ...state };
      mutableState[action.filterName as FilterName].show = true;
      return mutableState;
    }
    case FiltersAction.SET_VALUE: {
      const mutableState = { ...state };
      mutableState[action.filterName as FilterName].value = action.value;
      mutableState[action.filterName as FilterName].show = true;
      return mutableState;
    }
  }
};

export default filtersReducer;
