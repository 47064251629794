import React from "react";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";
import { Link } from "react-router-dom";
import { Attachment } from "../../../../models";
import { businessAreaSettings } from "../../../helpers/businessAreaSettings";
import FileUploadDocumentsModal from "../../../shared/actionModals/fileUploadModalDocuments";
import { dbDateStringToLocalDate } from "../../../shared/helpers/dates";

export default function Documents() {
  return (
    <CasesDetailsConsumer>
      {(context) => {
        return (
          <>
            <div className="content-container p-3">
              <button
                onClick={() => context.actions.toggleDocumentsAttachments(true)}
                className="btn btn-sm btn-primary float-right mb-3"
              >
                <i className="fas fa-upload mr-2"></i>
                Upload Files
              </button>
              <table className="table">
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>User</th>
                    <th>Timestamp</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {context.state.attachments.map((attachment: Attachment, index: number) => {
                    const date = dbDateStringToLocalDate(attachment.uploadDateTime).toLocaleString();
                    return (
                      <tr key={index}>
                        <td>{attachment.displayFileName ? attachment.displayFileName : attachment.fileName}</td>
                        <td>{attachment.uploadedBy}</td>
                        <td>{date}</td>
                        <td style={{ textAlign: "right" }}>
                          <Link
                            to={`/case/${context.state.case?.caseReference}/document/${attachment.attachmentType}/${attachment.attachmentId}`}
                          >
                            {businessAreaSettings().openAttachmentsInNewTab && attachment.mimeType === "text/html" ? 
                              "Open in new tab" : "View"}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <FileUploadDocumentsModal
              show={context.state.isDocumentsAttachmentsOpen}
              handleClose={context.actions.toggleDocumentsAttachments}
              handleUpload={context.actions.attachmentsUploaded}
              url={`attachments/upload/${context.state.case?.id}`}
            />
          </>
        );
      }}
    </CasesDetailsConsumer>
  );
}
