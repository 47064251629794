import React from "react";
import { CasesDetailsConsumer } from "../../../../../context/casesDetailsContextProvider";
import HtmlSanitiser from "../../../../shared/htmlSanitiser";
import { Attachment } from "../../../../../models";
import { Link } from "react-router-dom";
import { dbDateStringToLocalDate } from "../../../../shared/helpers/dates";

export default function MessageContent() {
  return (
    <CasesDetailsConsumer>
      {(context) => {
        const date =
            context.state.selectedEmail?.emailDirection === "Sent"
                ? dbDateStringToLocalDate(context.state.selectedEmail?.emailDate).toLocaleString()
                : dbDateStringToLocalDate(context.state.selectedEmail?.ingestDate).toLocaleString();

        const toAddresses = context.state.selectedEmail?.toAddresses?.split(";").filter((e: any) => e !== " ");
        const ccAddresses = context.state.selectedEmail?.ccAddresses?.split(";").filter((e: any) => e !== " ");

        let imageLetter = context.state.selectedEmail?.fromAddress.charAt(0);
        if (context.state.selectedEmail?.fromName) {
          const nameArray = context.state.selectedEmail?.fromName.split(" ");
          imageLetter = nameArray[0].charAt(0) + nameArray[nameArray.length - 1].charAt(0);
        }

        return (
          <div className="message">
            <div className="message-title">
              {context.state.selectedEmail && context.state.selectedEmail.importedSuccessfully === false && (
                <div className="errors-list">
                  <i className="fas fa-exclamation-triangle warning"></i>&nbsp; Issue importing this email.
                </div>
              )}
              {context.state.selectedEmail?.removedFromInbox === true && (
                <div className="errors-list">
                  <i className="fas fa-exclamation-triangle emergency-warning"></i>&nbsp; This email has been removed from the Outlook Inbox..
                </div>
              )}

              <div className="subject">{context.state.selectedEmail?.subject}</div>
              <div className="info">
                <div className="message-image">
                  <span className="image">{imageLetter}</span>
                </div>
                <div className="details">
                  <div className="top">
                    <div className="name">
                      {context.state.selectedEmail?.fromName
                        ? `${context.state.selectedEmail?.fromName} <${context.state.selectedEmail?.fromAddress}>`
                        : context.state.selectedEmail?.fromAddress}
                    </div>
                    <div className="date">{date}</div>
                  </div>

                  <div className="to">
                    to{" "}
                    {toAddresses?.map((t: string, index: number) => {
                      return (
                        <span className="email-pill" key={index}>
                          {t.replace(" ", "")}
                        </span>
                      );
                    })}
                  </div>

                  {context.state.selectedEmail?.ccAddresses ? (
                    <div className="cc">
                      cc{" "}
                      {ccAddresses.map((c: string, index: number) => {
                        return (
                          <span className="email-pill" key={index}>
                            {c.replace(" ", "")}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {context.state.selectedEmail?.attachments.length > 0 ? (
                    <div className="attachments">
                      Attachments:
                      {context.state.selectedEmail?.attachments.map((attachment: Attachment, index: number) => {
                        return (
                          <Link
                            key={index}
                            to={`/case/${context.state.case?.caseReference}/document/${attachment.attachmentType}/${attachment.attachmentId}`}
                          >
                            {attachment.displayFileName ? attachment.displayFileName : attachment.fileName};
                          </Link>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {context.state.selectedEmail?.webLink ? (
                    <div className="links">
                      <a
                        href={context.state.selectedEmail.webLink}
                        className="lint-outlook btn btn-link btn-sm pl-0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View in Outlook Web
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="message-body">
              <HtmlSanitiser content={context.state.selectedEmail?.message}></HtmlSanitiser>
            </div>
          </div>
        );
      }}
    </CasesDetailsConsumer>
  );
}
