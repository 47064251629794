import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";

import { getPollingAddresses } from "../../../services/get.data.service";
import { ResponseData } from "../../../models";
import { PollingAddress } from "../../../models/pollingAddress";
import { dbDateStringToLocalDate } from "../../shared/helpers/dates";

const DashboardPolling = (): JSX.Element => {
    const [
        pollingData,
        setpollingData,
    ] = useState<ResponseData<PollingAddress[]> | null>();
    const colHeadings: string[] = ["Email", "Actively Polling", "Polling Emails After"];

    const setupPolling = async (): Promise<void> => {
        // Check if polling has happened every 60s
        setTimeout((): void => {
            getPollingAddresses().then((response) => setpollingData(response));
            setupPolling();
        }, 60000);
    };

    useEffect((): void => {
        getPollingAddresses().then((response) => setpollingData(response));
        setupPolling();
    }, []);

    return (
        <TableContainer classes={{ root: "polling-table" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {colHeadings.map((heading, index) => {
                            return <TableCell key={index}>{heading}</TableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pollingData === undefined && (
                        <TableRow>
                            <TableCell classes={{ root: "test-loading-row" }}>
                                Loading...
                            </TableCell>
                        </TableRow>
                    )}
                    {pollingData?.data.map((account, index) => {
                        let pollFrom = null;

                        if (account.pollFrom != null) {
                            pollFrom = dbDateStringToLocalDate(account.pollFrom);
                        }

                        const pollFromString = pollFrom != null ? pollFrom.toLocaleString() : "Not Applicable";

                        return (
                            <TableRow key={index}>
                                <TableCell>{account.emailAddress}</TableCell>
                                <TableCell>{account.activelyPolling ? "Yes" : "No"}</TableCell>
                                <TableCell>
                                    {pollFromString}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DashboardPolling;
