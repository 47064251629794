import React, { useEffect, useState } from "react";
import { dbDateStringToLocalDate } from "../../../shared/helpers/dates";

export default function TimeRemaining(props: any) {
  const [timeRemaining, setTimeRemaining] = useState<string>();

    useEffect(() => {
        const countDownDate = dbDateStringToLocalDate(props.state.case?.nextSla).getTime();

    if (props.state.case?.nextSla) {
      const x = setInterval(function () {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance >= 0)
          setTimeRemaining(
            `${days > 0 ? `${days} days` : ""} ${
              hours < 10 ? `0${hours}` : hours
            }:${minutes < 10 ? `0${minutes}` : minutes}:${
              seconds < 10 ? `0${seconds}` : seconds
            }`
          );

        if (distance < 0) {
          clearInterval(x);
          setTimeRemaining("SLA Breached");
        }
      }, 1000);

      return () => {
        clearInterval(x);
      };
    }
  });

  let sladiv;
  if (props.state.case) {
    const text =
      props.state.case.status === "Closed"
        ? "Closed"
        : props.state.case.pausedSla
        ? "Paused"
        : timeRemaining;
    sladiv = <span className="badge badge-pill badge-success">{text}</span>;
    if (props.state.case.amberSlaWarning)
      sladiv = <span className="badge badge-pill badge-warning">{text}</span>;
    if (timeRemaining === "SLA Breached")
      sladiv = <span className="badge badge-pill badge-danger">{text}</span>;
  }

  return <>{sladiv}</>;
}
