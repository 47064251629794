import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ACD, Classification, ClassificationSLA } from "../../../../../models";
import { getAllClassifications } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import NumberInput from "../../shared/fields/numberInput";
import TextInput from "../../shared/fields/textInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";
import SLASelect from "../../shared/slaSelect";
import { SelectOption } from "../../../../../models/selectOption";

type Props = {
  isDuplicate: (fieldToCheck: keyof ACD, value: string)=> boolean;
  existingACD?: ACD;
  hub: HubService;
};

const ClientForm: FC<Props> = ({ isDuplicate, existingACD, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);

  const [classificationOptions, setClassificationOptions] = useState<SelectOption[]>();

  const [clientCode, setClientCode] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [defaultSlaHours, setDefaultSlaHours] = useState<number>(0);
  const [amberThresholdHours, setAmberThresholdHours] = useState<number>(0);
  const [classificationSlas, setClassificationSlas] = useState<ClassificationSLA[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existingACD) {
      setClientCode(existingACD.clientCode);
      setClientName(existingACD.clientName);
      setDefaultSlaHours(existingACD.slaHours);
      setAmberThresholdHours(existingACD.amberThresholdHours);
      setClassificationSlas(existingACD.classificationSlas);
    }
    getAllClassifications().then((response) =>{
      setClassificationOptions(response.data.map((classification: Classification): SelectOption =>{
        return {
          id: classification.classificationId,
          label: classification.name
        }
      }));
    });
  }, [existingACD]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (clientCode.length === 0) {
      errorMessages.push("Client Code is required");
    } else {
      if (isDuplicate("clientCode", clientCode)) {
        errorMessages.push("Client Code has already been used");
      }
    }
    if (clientName.length === 0) {
      errorMessages.push("Client Name is required");
    } else {
      if (isDuplicate("clientName", clientName)) {
        errorMessages.push("Client Name has already been used");
      }
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existingACD) {
        sendSetting(hub, {
            type: "UpdateAcd",
            payload: {
              amberThresholdHours,
              clientCode: clientCode.trim(),
              clientId: existingACD.clientId,
              clientName: clientName.trim(),
              slaHours: defaultSlaHours,
              slas: classificationSlas,
            },
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "CreateAcd",
            payload: {
              amberThresholdHours,
              clientCode: clientCode.trim(),
              clientName: clientName.trim(),
              slaHours: defaultSlaHours,
              slas: classificationSlas,
            },
          }, setRedirect);
      }
    }
  };

  if (redirect) return <Redirect to="/settings/acds/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingACD ? "Edit ACD" : "Create ACD"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingACD?.clientCode} >
          {existingACD?.clientName}
        </SettingsFormInfo>
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput 
              label="Client" 
              name="client-code" 
              onChange={setClientCode} 
              value={clientCode} 
            />
            <TextInput 
              label="Name" 
              name="client-name" 
              onChange={setClientName} 
              value={clientName} 
            />
            <NumberInput 
              label="SLA Time hh" 
              name="sla-hours" 
              onChange={(h): void => setDefaultSlaHours(parseInt(h.toString()))} 
              value={defaultSlaHours}
            />
            <NumberInput 
              label="Warning Time hh" 
              name="amber-threshold-hours" 
              onChange={(h): void => setAmberThresholdHours(parseInt(h.toString()))} 
              value={amberThresholdHours} 
            />
            <SLASelect
              options={classificationOptions}
              onChange={(h: any)=>{

                setClassificationSlas(h)
              }}
              value={classificationSlas}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientForm;
