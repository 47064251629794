import React from "react";
import { formatBytes, IPreviewProps } from "react-dropzone-uploader";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const Preview = ({
  canRemove,
  fileWithMeta: { remove, restart },
  meta: { name = '', percent, size = 0, status, validationError },
}: IPreviewProps): JSX.Element =>{
  return (
    <div className="row" style={{padding: "15px"}}>
      <span className="col-md-6">{name}, {formatBytes(size)}</span>
      {status === 'ready' && <span className="col-4 badge badge-pill">ready to upload</span>}
      {status === 'error_file_size' && <span className="col-4 badge badge-pill badge-danger">File too big</span>}
      {status === 'error_validation' && <span className="col-4 badge badge-pill badge-danger">{String(validationError)}</span>}
      {["done","headersreceived","getting_upload_params","started",'uploading'].includes(status) &&
        <span className="col-4" > 
          <progress style={{width: "100%"}} max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent} />
        </span>}
      {['error_upload_params', 'exception_upload', 'error_upload', 'aborted'].includes(status) &&         
        <span className="col-4 badge badge-pill badge-danger">upload failed</span>
      }
      <span className="col-2">
        {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
          <button>
            <ClearOutlinedIcon onClick={remove} />
          </button>
        )}
        {['error_upload_params', 'exception_upload', 'error_upload', 'aborted'].includes(status) &&
          <button>
            <ReplayOutlinedIcon onClick={restart} />
          </button>
        }
      </span>
    </div>
  )
}
export default Preview;