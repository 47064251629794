import Dropzone, { accepts, IDropzoneProps, IFileWithMeta } from "react-dropzone-uploader";

class AeDropzone extends Dropzone {
  
  constructor(props: IDropzoneProps) {
    super(props);
  }

  handleFiles = (files: File[]) => {
    files.forEach((file, i) => {
      if(!accepts(file, this.props.accept)){
        const { name, size, type } = file;
        const fileWithMeta = {
          file,
          meta: { name, size, type, id: `${new Date().getTime()}-${i}` },
        } as IFileWithMeta;
        fileWithMeta.meta.status = 'error_validation';
        fileWithMeta.meta.validationError = 'This type of file is not valid';
        fileWithMeta.remove = () => this.handleRemove(fileWithMeta);
        this.files.push(fileWithMeta);
        this.handleChangeStatus(fileWithMeta);
        this.forceUpdate();
      } else{
        this.handleFile(file, `${new Date().getTime()}-${i}`);
      }
    });
    const { current } = this.dropzone
    if (current) setTimeout(() => current.scroll({ top: current.scrollHeight, behavior: 'smooth' }), 150)
  }
}
export default AeDropzone;