import React from "react";
import { Link } from "react-router-dom";
import DataTable from "../shared/data-table/table";
import { SummaryCase } from "../../../../models/case";
import { isSpam } from "../../details/helpers/caseSpam";
import { Tooltip } from "@material-ui/core";
import { useTeamQContext } from "../../../../context/teamQContextProvider";
import { mapCaseDataToRowData, RowMappingOutputData } from "./mapCaseDataToRowData"; 
import { tableHeaders } from "../constants";
import { dbDateStringToLocalDate } from "../../../shared/helpers/dates";

const Cases = (): JSX.Element => {
  const { state: {cases, casesInFlight}} = useTeamQContext();
  const rows: RowMappingOutputData[] = [];
  let sladiv = <></>;

  if (!cases) return <></>;
  
  cases.forEach((claim: SummaryCase) => {
    sladiv = <></>;

    if (claim.timeRemaining) {
      const text =
        claim.status === "Closed"
          ? "Closed"
          : claim.pausedSla
          ? "Paused"
          : claim.timeRemaining;
      sladiv = <span className="badge badge-pill badge-success">{text}</span>;
      if (claim.amberSla)
        sladiv = <span className="badge badge-pill badge-warning">{text}</span>;
      if (claim.breachedSla)
        sladiv = <span className="badge badge-pill badge-danger">{text}</span>;
    }


  const from = claim.investorEmail.length > 0 ? claim.investorEmail : "Created manually";

    rows.push(
      mapCaseDataToRowData({
        caseIdAndSpamStatus: (
          <>   
            <Link 
              className="undecorated-link" 
              to={`case/${claim.caseReference}`}>
              {claim.caseReference}
            </Link>            
            {isSpam(claim.spamStatus) ? (
              <Tooltip
                title={`This case has been marked as "${claim.spamStatus}"`}
                aria-label={`This case has been marked as "${claim.spamStatus}"`}
              >
                <i className="fas fa-exclamation-triangle spam-warning"></i>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        ),
        caseRef: claim.caseReference,
        classification: claim.classification,
        clientID: claim.client,
        clientCaseId: claim.clientCaseId,
        confidence: claim.aiProbability,
        createdDate: dbDateStringToLocalDate(claim.createdDate),
        description: claim.description,
        from: from,
        id: claim.caseReference,
        owner: claim.owner,
        readonly:
          casesInFlight.indexOf(claim.caseReference) !== -1,
        status: claim.status, 
        subject:
          claim.subject.length > 80
            ? `${claim.subject.substring(0, 80)}...`
            : claim.subject,
        team: claim.team,
        timeRemaining: sladiv,
      })
    );
  });

  return (
    <div className="cases">
      {rows.length > 0 ? (
        <DataTable
          headers={ tableHeaders }
          rows={rows}
        ></DataTable>
      ) : (
        <div className="none-found">No cases found!</div>
      )}
    </div>
  );
};
export default Cases;
