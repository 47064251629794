import { RecsStatementsFileType } from "../../../models/reconciliationStatement";
import { getRecsStatement } from "../../../services/get.data.service";

const downloadStatement = (content: Blob, name: string) => {
  const binaryData = [];
  binaryData.push(content);
  const href = window.URL.createObjectURL(new Blob(binaryData));
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

const downloadStatements = (statementId: number, name: string ): void => {
  getRecsStatement(statementId, RecsStatementsFileType.Original).then((content: Blob) => {
    downloadStatement(content, `${name}.pdf`);
  });
  getRecsStatement(statementId, RecsStatementsFileType.Transactions).then((content: Blob) => {
    downloadStatement(content, `${name}_transactions.csv`);
  });
  getRecsStatement(statementId, RecsStatementsFileType.Balances).then((content: Blob) => {
    downloadStatement(content, `${name}_transactions.csv`);
  });
}
export default downloadStatements;