import React, { FC } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Sidenav } from "../../shared/sidenav/sidenav";
import { BootstrapInput } from "../../shared/components/bootstrapInput";

interface Props {
  onChange: (value: string) => void;
  options: string[];
  value: string;
}

const RecsStatementsSidenav: FC<Props> = ({ onChange, options, value}) => {
  return (
    <Sidenav>      
      <div className="form-group">
        <label htmlFor="year-month">Statements For:</label>
        <FormControl>
          <Select
              name="year-month"
              id="year-month"
            labelId={`year-month--label`}
            displayEmpty
            input={<BootstrapInput />}
            onChange={(e): void => onChange(String(e.target.value))}
            value={value}
          >
            {options.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Sidenav>
  );
}
export default RecsStatementsSidenav;