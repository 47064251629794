import React, { FC } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { SelectOption } from "../../../../../models/selectOption";

type Props = {
  label: string;
  name: string;
  onChange: (ids: number[]) => void;
  ids: number[];  
  options: SelectOption[];
};

const MultiInput: FC<Props> = ({ label, name, onChange, ids, options}) => {
  const selected: SelectOption[] = options.filter((option)=>ids.includes(option.id));
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>

      <Typeahead
        id={name}
        multiple
        positionFixed
        onChange={(selected: SelectOption[]):void =>
          onChange(selected.map((option)=>option.id))
        }
        selected={selected}
        options={options}
      />
    </div>
  );
};
export default MultiInput;