
export interface Attachment {
  attachmentId: number;
  attachmentType: AttachmentType; 
  fileName: string;
  displayFileName?: string;
  fileType: FileType;
  mimeType: string;
  s3Bucket: string;
  uploadDateTime: string;
  uploadedBy: string;
  url: string
}

export enum  AttachmentType {
  external = "External",
  internal = "Internal",
}

export enum FileType {
  png  = "png",
}
