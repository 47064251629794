export const transferFormsHeaders: string[] = [
  "Account_Holder",
  "Account_Holder_Personal_Number",
  "Client_Identifier",
  "Product",
  "Receiving_Account_Number",
  "Counterparty",
  "Bank_Account",
  "Transfer_Type",
  "Transfer_Method",
  "Funds",
  "Securities",
  "Cash",
  "Sell_Instructions",
  "Account_Closure",
  "ISINs_to_Re-Register",
  "ISINs_to_Sell",
  "Signatures",
  "Appointed",
  "DateTimeStamp",
  "AE_Case_Ref",
  "Attachment_File_Name"
] 