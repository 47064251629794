import React from "react";
import { ISubmitButtonProps } from "react-dropzone-uploader";

const SubmitButton = ({content, files, onSubmit}: ISubmitButtonProps) => {
  const handleSubmit = () => {
    onSubmit(files.filter(f => f.meta.status === 'ready'));
  }

  return (      
    <button style={{margin: "0 15px"}}
      className="btn btn-primary"
      onClick={handleSubmit}
     >
        {content}
     </button> 
  )
}
export default SubmitButton;