import axios, { AxiosError, AxiosResponse } from "axios";
import { Group, NewTeam, Team, UnsavedGroup } from "../models";
import { ResponseData } from "../models/data";
import { NewCase } from "../models/newCase";
import {
  apiUrl,
  axiosConfig,
  showError,
  handleError,
} from "./shared/dataServiceHelpers";

const postDataByUrl = (
  url: string,
  data: unknown
): Promise<ResponseData<unknown>> => {
  return axios
    .post(`${apiUrl}${url}`, data, axiosConfig())
    .then((axiosRes: AxiosResponse) => {
      const response: ResponseData<unknown> = axiosRes?.data;
      if (!response.success)
        showError(response.message || "Unknown error while updating data");
      return response as ResponseData<unknown>;
    })
    .catch((error: AxiosError) => {
      handleError(error);
      return error.response?.data as ResponseData<unknown>;
    });
};

// Login
export const logOut = (): Promise<unknown> => {
  return axios
    .post(`${apiUrl}authentication/logout`, null, axiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch(handleError);
};

export const postRestartPollerRequest =(): Promise<ResponseData<boolean>> => {
  return postDataByUrl("restartpoller/restart", null) as Promise<ResponseData<boolean>>;
}

export const postNewCase = (newCase: NewCase): Promise<ResponseData<string>> =>{
  return postDataByUrl("cases/createnewcase", newCase) as Promise<ResponseData<string>>;
} 

export const createTeam = (
  data: NewTeam
): Promise<ResponseData<Team>> => {
  return postDataByUrl("teams/createteam", data) as Promise<ResponseData<Team>>;
};

export const updateTeam = (
  data: Team
): Promise<ResponseData<Team>> => {
  return postDataByUrl(`teams/updateteam/${data.teamId}`, data) as Promise<ResponseData<Team>>;
};

export const postGroup = (group: UnsavedGroup): Promise<ResponseData<Group>> => {
  return postDataByUrl(`groups/postgroup`, group) as Promise<ResponseData<Group>>;
}