export interface Team {
  teamId: number;
  name: string;
}

export type NewTeam = {
  name: string;
}

export type TeamAccess = {
  classificationId: number;
  classificationName: string;
  groupId: number;
  teamId?: number;
  teamName?: string;
}

export type PutTeamAccess = {
  classificationId: number;
  groupId: number;
  teamId?: number;
}

export interface TeamUser {
  teamId: number;
  teamName: string;
}

export enum TeamRole {
  isManager = "management",
  hasRecsStatementsAccess = "recs statements",
  isSysAdmin = "system admin",
}

export const groupTeamsFilter = (value: unknown): boolean =>{
  const team = value as Team;        
  const roles = Object.values(TeamRole) as string[];
  return !roles.includes(team.name.toLowerCase());
}

export const roleTeamsFilter = (value: unknown): boolean =>{
  const team = value as Team;        
  const roles = Object.values(TeamRole) as string[];
  return roles.includes(team.name.toLowerCase());
}