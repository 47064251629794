export const dbDateStringToLocalDate = (dateString: string): Date => {
    //Add a Z to denote UTC, if there's not one.
    if (dateString != undefined &&
        dateString.length != undefined &&
        dateString[dateString.length - 1] != 'Z')
    {
        dateString += 'Z';
    }   

    return new Date(dateString);
}