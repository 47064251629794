import React from "react";
import { ILayoutProps } from "react-dropzone-uploader";

const LayoutWrapper = (closeButton?: JSX.Element): (props:ILayoutProps)=> JSX.Element =>  {

  const Layout = ({
    input,
    previews,
    dropzoneProps,
    files,
    extra: { maxFiles },
    submitButton,
  }: ILayoutProps): JSX.Element => {
    return (
      <div style={{overflow: "hidden"}}>
        <div {...dropzoneProps} >
          {files.length < maxFiles && input}
        </div>
        <div>{previews}</div>
        <div className="modal-footer" style={{marginTop: "1rem"}}>
          {closeButton}
          {files.length > 0 && files.find((file)=>file.meta.status === 'ready') &&
            submitButton}
        </div>
      </div>
    );
  };
  return Layout;
}

export default LayoutWrapper;