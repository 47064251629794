import React, { FC } from "react";
import { Sidenav } from "../../shared/sidenav/sidenav";


const ReportsSidenav: FC = () => {
  // const { path } = useRouteMatch();

  return (
    <Sidenav>
      <ul className="sidenav-menu">
      </ul>
    </Sidenav>
  );
}
export default ReportsSidenav;