import React, { FC, useEffect, useState } from "react";
import ReportsSidenav from "./reportsSidenav";
import SettingsTable from "../settings/shared/table/table";
import { ResultsTableRowData } from "../settings/pages/types";
import { getExistingTransferFormsReport, getNewTransferFormsReport, getTransferFormsReports } from "../../../services/get.data.service";
import { TransferFormsReport } from "../../../models/transferFormsReport";
import * as XLSX from "xlsx";
import { transferFormsHeaders } from "./constants";
import { dbDateStringToLocalDate } from "../../shared/helpers/dates";

const Reports: FC = () => {
  const [ createReport, setCreateReport ] = useState<boolean>(false);
  const [ downloadReportId, setDownloadReportId ] = useState<number|null>(null);
  const [ message, setMessage ] = useState<string>("");
  const [ reports, setReports] = useState<TransferFormsReport[]>([]);

    const downloadReport = (data: TransferFormsReport) => {
        const widths: number[] = transferFormsHeaders.map((header) => header.length);
        const rows = data.caseEmailAttachmentTransferForms.map((row) => {
            const result = [
                row.accountHolder,
                row.accountHolderPersonalNumber,
                row.clientIdentifier,
                row.product === "GIA" ? "Personal Portfolio" : row.product,
                row.receivingAccountNumber,
                row.counterparty,
                row.bankAccount,
                row.transferType,
                row.transferMethod,
                row.funds,
                row.securities,
                row.cash,
                row.sellInstructions,
                row.accountClosure,
                row.isinsToReregister,
                row.isinsToSell,
                row.signatures,
                row.appointed,
                row.aiTimestamp,
                row.caseReference,
                row.fileName
            ];
            result.forEach((value: string | Date, index: number) => {
                if (typeof value === "string") {
                    widths[index] = Math.max(widths[index], value.length);
                }
            });
            return result;
        });
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        worksheet["!cols"] = widths.map((width: number): XLSX.ColInfo => {
            return { width };
        });
        XLSX.utils.sheet_add_aoa(worksheet, [transferFormsHeaders], { origin: "A1" });
        XLSX.utils.book_append_sheet(workbook, worksheet, `Swedbank Transfer Forms`);
        XLSX.writeFile(workbook, `Swedbank Transfer Forms Download ${dbDateStringToLocalDate(data.timestamp).toLocaleString()}.xlsx`);  //TODO - Can we reformat this, it has a comma and might cause problems. Previous format was DD/MM/YYYY HH:MM
    };

  useEffect(()=>{
    getTransferFormsReports().then((response) => {
      setReports(response.data);
    });
  }, []);

  useEffect(()=>{
    if (downloadReportId != null) {
      setMessage("");
      getExistingTransferFormsReport(downloadReportId).then((response)=>{
        downloadReport(response.data);
      });
      setDownloadReportId(null);
    }
  }, [downloadReportId]);


  useEffect(()=>{
    if (createReport) {
      getNewTransferFormsReport().then((response)=>{ 
        if (response.dataCount === 0) {
          setMessage("No forms found since last report");
        } else {
          setMessage("");
          downloadReport(response.data);
          getTransferFormsReports().then((response) => {
            setReports(response.data);
          });
        }
      });
      setCreateReport(false);
    }
  }, [createReport]);

  const headers: string[] = ["Name", "Date of generating", "Generated by", ""];
  const rows: ResultsTableRowData[] = reports.map((report: TransferFormsReport)=>{
    return {
      id: report.transferFormsReportId,
      data: [
        "Swedbank Transfer Forms",
        dbDateStringToLocalDate(report.timestamp).toLocaleString(),
        report.generatedBy,    
        <div 
        style={{ textAlign: "center" }} 
        key={report.transferFormsReportId}
        > 
          <button            
            className="btn btn-secondary"
            onClick={():void => setDownloadReportId(report.transferFormsReportId)}
          >
            Download
          </button>
        </div>   
      ]
    }
  });

  return (
  <div className="page-layout">
    <ReportsSidenav />
    <div className="content">
      <div className="row">
        <div className="col-10">
          <h2 className="page-title">Transfer Forms Reports for Swedbank</h2>
          {message?.length > 0 &&
            <span className="badge badge-pill badge-warning">{message}</span>
          }
        </div>

        <div className="col-2">
            <button
              className="btn btn-primary"
              onClick={():void => setCreateReport(true)}
            >
              Create new report
            </button>
        </div>
      </div>
      <SettingsTable headers={headers} rows={rows} />
    </div>
  </div>
  );
}
export default Reports;