import React, { useState, FormEvent, useEffect } from "react";
import Bubble from "../shared/bubble";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";
import { isMine } from "../helpers/caseStatus";
import { CasesDetailsContext } from "../../../../context/casesDetails/types";
import { dbDateStringToLocalDate } from "../../../shared/helpers/dates";

export default function Notes() {
  const [noteState, setNoteState] = useState("");
  let el: any;

  const changeNote = (event: FormEvent<HTMLInputElement>) =>
    setNoteState(event.currentTarget.value);

  const sendNote = (
    event: React.FormEvent<HTMLFormElement>,
    context: CasesDetailsContext
  ): void => {
    event.preventDefault();
    if (noteState !== "") {
      context.actions.sendNote(noteState);
      setNoteState("");
    }
  };

  const [scrollState, setScrollState] = useState<boolean>(false);

  const notePanel = document.getElementById("note-panel");
  if (notePanel) {
    notePanel.onscroll = () => {
      const state =
        notePanel.scrollTop === notePanel.scrollHeight - notePanel.offsetHeight
          ? false
          : true;
      setScrollState(state);
    };
  }

  useEffect(() => {
    if (el) {
      if (!scrollState) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  return (
    <CasesDetailsConsumer>
      {(context) => (
        <div className="note-container">
          <div className="email-messages">
            <div className="content-container p-3">
              <div className="tab-title">
                {context.actions.validateEmailAddress(context.state.case?.emails[0].fromAddress || "")
                  ? "Email Communication"
                  : "Initial Communication"}
              </div>
                {context.state.emails.map((email: any, index: number) => {
                const eDate = dbDateStringToLocalDate(email.emailDate).toLocaleTimeString();
                const external = email.sending
                  ? "Sending..."
                  : `External ${eDate}`;
                const sending = email.sending ? "sending" : "";
                const direction =
                  email.emailDirection === "Sent" ? "right" : "left";
                const type =
                  email.emailDirection === "Sent" ? "primary" : "default";

                return (
                  <Bubble
                    direction={direction}
                    sending={sending}
                    type={type}
                    external={external}
                    key={index}
                  >
                    {email.textMessage}
                  </Bubble>
                );
              })}
            </div>
          </div>
          <div className="notes">
            <div className="tab-title">Internal Note</div>
            <div className="content-container p-3" id="note-panel">
              {context.state.notes.map((note: any, index: number) => {
                const date = dbDateStringToLocalDate(note.noteDate).toLocaleString();

                return (
                  <p
                    className={`note ${note.sending ? "note-sending" : ""}`}
                    key={index}
                  >
                    {note.note}
                    <small>
                      {note.sending
                        ? "Creating note..."
                        : `${note.user} - ${date}`}
                    </small>
                  </p>
                );
              })}

              <div className="bottom-div" ref={(e) => (el = e)}></div>
            </div>
            {isMine(context.state.caseStatus) ? (
              <div className="note-actions">
                <form onSubmit={(e) => sendNote(e, context)}>
                  <input
                    type="text"
                    aria-label="Type your message here"
                    placeholder="Type your message here..."
                    className="note-input"
                    value={noteState}
                    onChange={changeNote}
                  />
                  <div className="note-actions-row">
                    <div className="media">
                      <i className="fas fa-link"></i>
                      <i className="fas fa-film"></i>
                      <i className="fas fa-smile"></i>
                    </div>
                    <div className="save-button">
                      <button type="submit" className="btn btn-primary btn-sm">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </CasesDetailsConsumer>
  );
}
