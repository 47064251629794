import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import FileUpload from "../fileUpload/fileUpload";
import Note from "../components/note";
import { IFileWithMeta } from "react-dropzone-uploader";

interface Props {
  accept?: string;
  handleClose: (show: boolean) => void;
  handleUpload: (note: string)=>void;
  hasNote?: boolean;
  show: boolean;
  url: string;
  validate?: (file: IFileWithMeta)=>boolean|string;
}

export const FileUploadDocumentsModal: FC<Props> = ({
  accept,
  handleClose, 
  handleUpload, 
  hasNote = false,
  show, 
  validate,
  url
}) => {
  const [note, setNote] = useState("");
  
  const handleOnCompleted = () => {
    handleUpload(note);
    setNote("");
    handleClose(false);
  };
  
  const close = () => {
    handleClose(false);
  };

  return (
    <Modal show={show} animation={false} size={"xl"} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content-container pt-3">          
          {hasNote &&<Note note={note} setNote={setNote} />}
          <FileUpload
            accept={accept}
            closeButton={
              <button className="btn btn-secondary" onClick={close}>
                Close
              </button>
            }
            inputContent="Drop files here or click to select file"
            onCompleted={handleOnCompleted}
            validate={validate}
            url={url}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default FileUploadDocumentsModal;