import { ApiUser, groupTeamsFilter, Team, TeamRole, TeamUser, User } from "../../../models";

const mapApiUser = ({
      azureId, 
      emailAddress, 
      firstName, 
      hasTransferFormsGroupAccess,
      id, 
      lastName, 
      teams, 
      userIsRequester
    }: ApiUser
  ): User =>{
    const teamNames = teams.map((team) => team.teamName.toLowerCase());
    const teamList: Team[] = teams.map(({teamId, teamName}:TeamUser)=>{
      return {
        teamId,
        name: teamName
      }
    })
    return {
      azureId,
      emailAddress,
      firstName,
      id,
      lastName,
      isManager: teamNames.includes(TeamRole.isManager),
      hasRecsStatementsAccess: teamNames.includes(TeamRole.hasRecsStatementsAccess),
      isSysAdmin: teamNames.includes(TeamRole.isSysAdmin),
      hasTransferFormsGroupAccess, 
      teams: teamList.filter(groupTeamsFilter),
      userIsRequester,
    };
  }
  export default mapApiUser;