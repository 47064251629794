import React, { FC } from "react";
import CommonModal from "../components/commonModal";
import ModalButtons from "./shared/modalButtons";
import useModalState from "./hooks/useModalState";


interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (client: number, message: string) => void;
}

export const ResendToStudioModal: FC<Props> = ({ show = false, handleClose, handleSave }) => {
  
  const { close, save } = useModalState(
    handleClose,
    handleSave
  );

  return (
    <CommonModal
      show={show}
      title="Resend Cases to Studio"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Send Cases" />}
    >
      Is the Studio API back up? If you are not certain, just resend one case.
    </CommonModal>
  );
};
