import React, { createContext, FC, useContext, useEffect, useState } from "react";
import {
  getAllUsers,
  getFilters,
  getUserImage,
  upsertLogin,
} from "../services/get.data.service";
import { ApiUser, SavedFilter, User, UserContext } from "../models/user";
import HubService from "../services/hub.service";
import { v4 as uuid } from "uuid";
import { ResponseData } from "../models";
import mapApiUser from "../portal/shared/helpers/mapApiUser";

export enum Severity {
  "Error",
  "Fatal",
}

export type Error = {
  severity: Severity;
  message: string;
};

export type Props = {
  children: React.ReactNode;
};

const Context = createContext<UserContext>({} as UserContext);
export const useUserContext = (): UserContext => useContext(Context);

export const UserConsumer = Context.Consumer;

export const UserContextProvider: FC<Props> = ({ children }: Props) => {
  const [showError, setShowError] = useState<Error | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [userImage, setUserImage] = useState<string>("");
  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
  const [userHubConnection] = useState(new HubService("UserHub"));

  const loadFilters = (): void =>{    
    getFilters().then((response: ResponseData<SavedFilter[]>) => {
      setSavedFilters(response.data);
    });
  }

  useEffect(() => {
    upsertLogin()
      .then((response: ResponseData<ApiUser>) => {
        if (response.success && response.dataCount === 1 && response.data.emailAddress) {
          userHubConnection.start();
          setUser(mapApiUser(response.data));
        } else {
          setShowError({ severity: Severity.Fatal, message: "User not recognised" });
          console.error(response);
        }
      })
      .catch((error) => {
        setShowError({ severity: Severity.Fatal, message: "Error contacting server" });
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (user?.id) {
      getUserImage().then((response: ResponseData<string>) => {
        setUserImage(response.data);
      });
      getAllUsers().then((response: ResponseData<ApiUser[]>) => {
        const users = response.data.map(mapApiUser);
        setAllUsers(users);
      });
      loadFilters();
      userHubConnection.listen("FiltersUpdated", () => {
        loadFilters();
      });
    }
  }, [user]);

  const saveFilter = (queryString: string, filterName: string):void => {
    const filterData = {
      requestId: uuid(),
      filterName,
      queryString: queryString,
    };

    userHubConnection.send("AddUserFilter", filterData);
  };

  const deleteFilter = (filterId: string): void => {
    const filterData = {
      requestId: uuid(),
      filterId,
    };

    userHubConnection.send("DeleteUserFilter", filterData);
  };

  const userContext: UserContext = {
    state: {
      savedFilters,
      user,
      userImage,
      users: allUsers,
    },
    actions: {
      saveFilter: saveFilter,
      deleteFilter: deleteFilter,
    },
  };
  //  @TODO error display
  return (
    <Context.Provider value={userContext}>
      {showError?.message}
      {user && children}
    </Context.Provider>
  );
};
export default UserContextProvider;
