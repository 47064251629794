import React, { FC } from "react";
import {  FormControl } from "@material-ui/core";
import { Filter, FilterName } from "../../context/teamQ/reducers/filtersReducer";
import ToggleInput from "../../portal/pages/settings/shared/fields/toggleInput";


export type selectOptionsType = { id: string; label: string };
interface Props {
  filterName: FilterName;
  filter: Filter;
  options: selectOptionsType[];
  handleFilter: (name: string, value: boolean) => void;
}

export const BooleanInput: FC<Props> = ({ filterName, filter, handleFilter }) => {
  if (!filter.show) return null;

  return (
    <div className="form-group">
      <FormControl>
        <ToggleInput
          label={filter.label}
          name={filterName}
          onChange={(state:  boolean)=>{
            handleFilter(filterName, state);
          }}
          value={filter.value === "true"}
        />
      </FormControl>
    </div>
  );
};
