export interface ReconciliationStatement {
  reconciliationStatementId: number;
  fileName: string;
  status: RecsStatementStatus;
  uploadedAt: string;
  finishedProcessingAt: string;
  errorMessage: string;
}

export enum RecsStatementStatus {
  "Uploaded" = 0,
  "Completed Successfully" = 1,
  "Failed" = 2
}

export enum RecsStatementsFileType {
  Original = 0,
  Balances = 1,
  Transactions = 2,
}