import React, { FC } from "react";

interface Props {
  note: string;
  setNote: (note: string) => void;
}

const Note: FC<Props> = ({note, setNote}) => {

  return (
    <div className="form-group">
      <label htmlFor="upload-note">Note</label>
      <textarea
        id="upload-note"
        name="subject"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="form-control"
      ></textarea>
    </div>
  );
}
export default Note;