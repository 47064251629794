import React, { FC } from "react";
import {
  IDropzoneProps,
  IFileWithMeta,
  StatusValue,
} from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { apiUrl } from "../../../services/shared/dataServiceHelpers";
import { getToken } from "../../../services/auth.service";
import Preview from "./preview";
import SubmitButton from "./submitButton";
import AeDropzone from "./aeDropzone";
import LayoutWrapper from "./layoutWrapper";


interface Props {
  accept?: string;
  closeButton?: JSX.Element;
  initialFiles?: File[];
  inputContent: string;
  onCompleted: ()=>void;
  submitButtonContent?: string;
  url: string;
  validate?: (file: IFileWithMeta)=>boolean|string;
}

export const FileUpload: FC<Props> = ({
  accept,
  closeButton,
  initialFiles, 
  inputContent, 
  onCompleted, 
  submitButtonContent = "Upload files", 
  url, 
  validate
}) => {
  const handleOnChange: IDropzoneProps["onChangeStatus"] = (
    file: IFileWithMeta, 
    status: StatusValue, 
    allFiles: IFileWithMeta[]
  ): void => {
    if (allFiles.every((file: IFileWithMeta)=>file.meta.status === "done")) {
      onCompleted();
    }
  };

  const handleOnSubmit: IDropzoneProps["onSubmit"] = (files) => {                
    files.forEach((file)=>{
      if (file.meta.status === 'ready') {
        file.restart();
      }
    });
  }

  const handleUploadParams: IDropzoneProps["getUploadParams"] = () => {
    return { 
      url: `${apiUrl}${url}`,
      headers: { Authorization: `Bearer ${getToken()}`}
    }
  }

  return (
    <>
      <AeDropzone 
        accept={accept}
        autoUpload={false}
        classNames={{inputLabelWithFiles:"dzu-inputLabel"}}
        initialFiles={initialFiles}
        inputContent={inputContent}
        inputWithFilesContent={inputContent}
        getUploadParams={handleUploadParams}
        onChangeStatus={handleOnChange}
        onSubmit={handleOnSubmit}
        styles={{dropzone:{overflow: "hidden"}, preview:{padding:"0 10px"}}}
        submitButtonContent={submitButtonContent}
        validate={validate}
        LayoutComponent={LayoutWrapper(closeButton)}
        PreviewComponent={Preview}
        SubmitButtonComponent={SubmitButton}
      />
    </>
  );
}
export default FileUpload