import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Attachment, ResponseData } from "../../../../models";
import { getAttachment, getAttachmentContent } from "../../../../services/get.data.service";
import { businessAreaSettings } from "../../../helpers/businessAreaSettings";
import Loading from "../../../shared/loading";

type DocumentParams = {
  attachmentType?: string;
  attachmentId?: string;
};

const ShowDocument: FC = () => {
  const { attachmentType, attachmentId }: DocumentParams = useParams();
  const history = useHistory();
  const docContainer = useRef<HTMLIFrameElement>(null);
  const link = useRef<HTMLAnchorElement>(null);
  const [attachment, setAttachment] = useState<Attachment>();
  const [href, setHref] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (docContainer.current && attachmentType && attachmentId) {
      getAttachment(attachmentType, Number(attachmentId)).then((response: ResponseData<Attachment>) => {
        setAttachment(response.data);
      });
    }
  }, []);

  useEffect(() => {
    if (attachment && docContainer.current && attachmentType && attachmentId) {
      getAttachmentContent(attachmentType, Number(attachmentId)).then((content: Blob) => {
        const binaryData = [];
        binaryData.push(content);
        const href = window.URL.createObjectURL(new Blob(binaryData, { type: attachment.mimeType }));
        if (businessAreaSettings().openAttachmentsInNewTab && attachment.mimeType === "text/html") {
          window.open(href, "show document");
          history.goBack();
        } else {
          if (["text/html","application/pdf", "image/png", "image/gif", "image/jpeg"].includes(attachment.mimeType)) {
            if (docContainer?.current) {
              const frame = docContainer.current;
              frame.src = href;
            }
          } else {
            setLoading(false);
            setHref(href);
          }
        }
      });
    }
  }, [attachment]);

  useEffect(()=>{
    if (link?.current) {
      link.current.click();
      link.current.hidden = true;
    }
  }, [href]);
  
  return (
    <div className="content-container" >
        <div className="document-view">
          <div className="row p-3">
            <div className="col-3 pl-3 ">{attachment?.displayFileName ? attachment.displayFileName : attachment?.fileName}</div>
            <div className="col-3">{attachment?.uploadedBy}</div>
            <div className="col-3">{attachment?.uploadDateTime.toLocaleString()}</div>
            <div className="col-3 text-right">
              <button className="btn btn-sm btn-primary" onClick={history.goBack}>
                  <i className="fas fa-chevron-left mr-2"></i>
                Back
              </button>
            </div>
          </div>
          {href && <a href={href} ref={link} download={attachment?.displayFileName ? attachment.displayFileName : attachment?.fileName} >Download</a>}
          {loading && <Loading containerClass="dashboard-loading"></Loading>}
          <iframe id="docContainer" hidden={loading || href.length > 0} ref={docContainer} onLoad={():void=>setLoading(false)}></iframe>
      </div>
    </div>
  );
};

export default ShowDocument;
