import React from "react";
import { CasesDetailsConsumer } from "../../../context/casesDetailsContextProvider";
import { dbDateStringToLocalDate } from "../helpers/dates";

export default function FileSelect(params: any) {
  return (
    <CasesDetailsConsumer>
      {(context) => {
        return (
          <table className="table">
            <thead>
              <tr>
                <th>Document</th>
                <th>User</th>
                <th>Timestamp</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {context.state.attachments.map(
                (attachment: any, index: number) => {
                  const date = dbDateStringToLocalDate(attachment.uploadDateTime).toLocaleString()
                  return (
                    <tr key={index}>
                      <td>{attachment.displayFileName ? attachment.displayFileName : attachment.fileName}</td>
                      <td>{attachment.uploadedBy}</td>
                      <td>{date}</td>
                      <td style={{ textAlign: "right" }}>
                        <button
                          className="btn btn-link"
                          onClick={() => params.toggleFile(attachment)}
                        >
                          {params.selectedFiles.includes(attachment)
                            ? "Unselect"
                            : "Select"}
                        </button>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        );
      }}
    </CasesDetailsConsumer>
  );
}
