import { useEffect, useState } from "react";
import { ACD, SummaryCase, Classification, Team, groupTeamsFilter } from "../../../models";
import { TeamQContext } from "../types";
import { getACDs, getAllCases, getClassificationsForUser, getTeams } from "../../../services/get.data.service";
import { displaySettingsAction, PageName } from "../reducers/displaySettingsReducer";

import usePolling from "./usePolling";
import { readLocalStorage, writeLocalStorage } from "./localStorage";
import { getQueryString } from "../helpers/getQueryString";

export const useLoadData = (
  context: TeamQContext,
  dataLoad: boolean,
  setAcds: (acds: ACD[]) => void,
  setCases: (cases: SummaryCase[]) => void,
  setClassifications: (acds: Classification[]) => void,
  setDataLoad: (state: boolean) => void,
  setTeams: (acds: Team[]) => void,
  setTotalCasesCount: (count: number) => void,
  setMyCasesCount: (count: number) => void
): void => {
  const {
    state: { displaySettings, filters },
    dispatch: { displaySettings: displaySettingsDispatch, filters: filtersDispatch },
  } = context;

  const getCaseData = async (): Promise<void> => {
    const { pageName, pagination } = displaySettings;
    const queryString: string = getQueryString(filters, displaySettings.sort);
  
    const fullQueryString = `?pageindex=${pagination.pageIndex}&pagesize=${pagination.pageSize}&${queryString}`;

    return await getAllCases(pageName, fullQueryString).then((res) => {
      displaySettingsDispatch({ type: displaySettingsAction.SET_COUNT, count: res?.dataCount || 0 });
      if (pageName === PageName.GET_ALL_FOR_USER) {
        setTotalCasesCount(res.dataCount);

        getAllCases(PageName.GET_MY_CASES, fullQueryString).then((res) => {
          setMyCasesCount(res.dataCount);
        });
      } else if (pageName === PageName.GET_MY_CASES) {
        setMyCasesCount(res.dataCount);
        getAllCases(PageName.GET_ALL_FOR_USER, fullQueryString).then((res) => {
          setTotalCasesCount(res.dataCount);
        });
      }
      setCases(res.data || []);
    });
  };

  const getAllData = async (): Promise<void> => {
    getACDs().then((result) => setAcds(result.data));
    getClassificationsForUser().then((result) => setClassifications(result.data));
    getTeams().then((result) => setTeams(result.data.filter(groupTeamsFilter)));
    getCaseData().then(() => {
      setDataLoad(false);
    });
  };
  const {
    pageName,
    pagination: { pageIndex, pageSize },
    sort: { headerToSort, direction },
  } = displaySettings;

  const [ casesLoad, setCasesLoad ] = useState<boolean>(false);
  const [localStorageRead, setLocalStorageRead] = useState<boolean>(false);

  useEffect(() => {
    if (!localStorageRead) {
      readLocalStorage(displaySettingsDispatch, filtersDispatch);
      setLocalStorageRead(true);
    } else {
      getAllData();
    }
  }, [direction, headerToSort, localStorageRead, pageIndex, pageName, pageSize]);

  useEffect(() => {
    if (localStorageRead) {
      writeLocalStorage(displaySettings, filters);
    }
  }, [direction, filters, headerToSort, pageName, pageSize]);

  useEffect(() => {
    if (dataLoad) {
      getAllData();
    }
  }, [dataLoad]);

  useEffect(() => {
    if (casesLoad) {
      getCaseData();
    }
  }, [casesLoad]);

  usePolling(()=>setCasesLoad(true));
};
