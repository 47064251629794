import { Attachment } from "./attachment";

export interface Email {
    caseEmailId: number;
    messageId: string;
    emailDirection: EmailDirection;
    fromAddress: string;
    fromName: string;
    ccAddresses: string; // delimited with ;
    toAddresses: string; // delimited with ;
    pollingEmailAddress: string; 
    subject: string;
    textMessage: string;
    message: string;
    emailDate: string;
    ingestDate: string|null,
    webLink: string,
    importedSuccessfully: boolean|null,
    removedFromInbox: boolean|null,
    attachments: Attachment[];
}

enum EmailDirection {
  received = "Received",
  sent = "Sent",
}