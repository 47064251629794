import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import FileSelect from "../components/fileSelect";
import FileUpload from "../fileUpload/fileUpload";
import Note from "../components/note";
import { useCaseContext } from "../../../context/casesDetailsContextProvider";

interface Props {
  handleClose: (show: boolean) => void;
  handleUpload: (note: string) => void;
  show: boolean;
  url: string;
}

const FileUploadModalSendEmail: FC<Props> = ({
  handleClose, 
  handleUpload, 
  show,
  url
}: Props) => {
  const [note, setNote] = useState("");
  const [tab, setTab] = useState("files");
  const {actions: {setEmailAttachments}, state: {emailSelectedAttachments}} = useCaseContext();

  const handleOnCompleted = () => {
    handleUpload(note);
    setNote("");
    setTab("files")
  };

  const toggleFile = (file: any) => {
    if (emailSelectedAttachments.includes(file)) {
      setEmailAttachments(emailSelectedAttachments.filter((sf) => sf !== file));
    } else {
      setEmailAttachments([...emailSelectedAttachments, file]);
    }
  };

  const close = () => {
    handleClose(false);
  };

  const tabsBody = () => {
    switch (tab) {
      case "files":
        return (
          <FileSelect
            selectedFiles={emailSelectedAttachments}
            toggleFile={toggleFile}
          ></FileSelect>
        );
      case "upload":
        return (
          <>
            <Note note={note} setNote={setNote} />
            <FileUpload
              closeButton={
                <button className="btn btn-secondary" onClick={close}>
                  Close
                </button>
              }
              inputContent="Drop files here or click to select file"
              onCompleted={handleOnCompleted}
              url={url}
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <Modal show={show} animation={false} size={"xl"} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="tabs">
          <li>
            <button
              className={tab === "files" ? "active" : ""}
              onClick={() => setTab("files")}
            >
              Files
            </button>
          </li>
          <li>
            <button
              className={tab === "upload" ? "active" : ""}
              onClick={() => setTab("upload")}
            >
              Upload
            </button>
          </li>
        </ul>
        <div className="content-container pt-3">{tabsBody()}</div>
      </Modal.Body>
        {tab === "files" && (
          <Modal.Footer> 
            <button className="btn btn-secondary" onClick={close}>
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={close}
            >
              Attach selected files {`(${emailSelectedAttachments.length} ${
                emailSelectedAttachments.length === 1 ? "file" : "files"
              })`}
            </button>
          </Modal.Footer>
        )}
    </Modal>
  );
}
export default FileUploadModalSendEmail;